import React from 'react'
import * as Icon from 'react-feather'

const GoTop = ({ scrollStepInPx, delayInMs }) => {
  const [thePosition, setThePosition] = React.useState(false)
  const timeoutRef = React.useRef(null)

  // Buggy scroll producing Warning: Can't perform a React state update on an unmounted component
  // Fix: define onScroll as named method and remove it on component unmount
  // React.useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     if (window.scrollY > 170) {
  //       setThePosition(true)
  //     } else {
  //       setThePosition(false)
  //     }
  //   })
  // }, [])

  // Running on mount
  React.useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 170) {
        setThePosition(true)
      } else {
        setThePosition(false)
      }
    }
    // Setting the event handler from web API
    document.addEventListener('scroll', onScroll)
    // Cleaning up from the web API
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(timeoutRef.current)
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx)
  }

  const scrollToTop = () => {
    timeoutRef.current = setInterval(onScrollStep, delayInMs)
  }

  const renderGoTopIcon = () => {
    return (
      <div
        className={`go-top ${thePosition ? 'active' : ''}`}
        role='none'
        onClick={scrollToTop}
      >
        <Icon.ArrowUp />
      </div>
    )
  }

  return <React.Fragment>{renderGoTopIcon()}</React.Fragment>
}

export default GoTop
